import { Text } from 'theme-ui'
import { Link } from 'gatsby'
import { HeaderProvider } from './contexts/header'
import { Header } from './components/Header'

const Draft = () => (
  <div
    sx={{
      p: 3,
      my: 4,
      fontWeight: 'bold',
      color: 'background',
      bg: 'accent',
      borderRadius: '5px',
    }}
  >
    ⚠️ You are viewing a draft page, and this may not be complete for primetime.
  </div>
)

const layout = (props: any) => {
  const title = props.pageContext?.frontmatter?.title
  let date = props.pageContext?.frontmatter?.date
  const minRead = props.pageContext?.frontmatter?.minRead

  if (date)
    date = new Date(date).toLocaleDateString('en-US', {
      timeZone: 'UTC',
    })
  const draft = props.pageContext?.frontmatter?.draft

  return (
    <div
      sx={{
        variant: 'styles.root',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <HeaderProvider>
        <Header />
        <main
          sx={{
            width: '100%',
            maxWidth: 'wide',
            px: 3,
            mt: 3,
            mx: 'auto',
            flexGrow: 1,
          }}
        >
          <div
            sx={{
              maxWidth: !!title ? 'container' : null,
            }}
          >
            {draft && <Draft />}
            {title && <Text as="h1">{title}</Text>}

            <div
              sx={{
                variant: 'text.small',
                fontWeight: 'bold',
              }}
            >
              {date && date} {minRead && `- ${minRead} min read`}
            </div>

            {props.children}
          </div>
        </main>
        <footer
          sx={{
            px: 3,
            width: '100%',
            maxWidth: 'wide',
            minHeight: '50px',
            mx: 'auto',
          }}
        >
          <div
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              fontSize: 0,
            }}
          >
            <Link
              to="/propaganda"
              sx={{
                variant: 'styles.navlink',
                mr: 3,
              }}
            >
              Propaganda
            </Link>
            <Link
              to="/timeline"
              sx={{
                variant: 'styles.navlink',
                mr: 4,
              }}
            >
              Know More about the history of the conflict
            </Link>
            <div sx={{ mx: 'auto' }} />
            <div sx={{ my: 2 }}>© 2023 Revealstine</div>
          </div>
        </footer>
      </HeaderProvider>
    </div>
  )
}

export default layout
