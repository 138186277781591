import { Flex, Text, useColorMode } from 'theme-ui'
import { useHeader } from '../contexts/header'
import { Link } from 'gatsby'
import Avatar from '../components/Avatar'
import border from '../svg/border.svg'

const modes = ['red', 'green', 'dark', 'light'] as const

type ColorMode = 'red' | 'green' | 'dark' | 'light'

interface ColorButtonProps {
  mode: ColorMode
  onClick: () => void
}

const ColorButton = ({ mode, ...props }: ColorButtonProps) => (
  <button
    {...props}
    title="Cycle Color Mode"
    sx={{
      display: 'inline-block',
      appearance: 'none',
      bg: 'transparent',
      color: 'inherit',
      p: 1,
      m: 0,
      border: 0,
      borderRadius: 9999,
      ':hover,:focus': {
        color: 'primary',
        boxShadow: '0 0 0 3px',
        outline: 'none',
      },
    }}
  >
    <svg
      viewBox="0 0 32 32"
      width="24"
      height="24"
      fill="currentcolor"
      sx={{
        display: 'block',
      }}
    >
      <circle
        cx="16"
        cy="16"
        r="14"
        fill="none"
        stroke="currentcolor"
        strokeWidth="4"
      />
      <path
        d={`
          M 16 0
          A 16 16 0 0 0 16 32
          z
        `}
      />
    </svg>
  </button>
)

export const Header = () => {
  const { headerRef } = useHeader()

  const [mode, setMode] = useColorMode<ColorMode>()
  const handleColorMode = () => {
    const i = modes.indexOf(mode)
    const n = (i + 1) % modes.length
    const next = modes[n]
    setMode(next)
    // shouldn't be necessary, but there is a weird interaction between theme-ui and gatsby Head API
    // probably mentioned here https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/#usage-notes
    document.documentElement.classList.value = `theme-ui-${next}`
  }
  return (
    <header
      ref={headerRef}
      sx={{
        width: '100%',
        minHeight: '60px',
        backdropFilter: 'blur(20px)',
        flexShrink: 0,
        display: 'flex',
        position: 'sticky',
        top: '0',
        alignItems: 'center',
        flexDirection: ['column', 'row', 'row'],
        rowGap: '10px',
        justifyContent: 'space-around',
        zIndex: 1000,
        mx: 'auto',
        px: 3,
        py: 2,
      }}
    >
      <Flex
        sx={{
          columnGap: [1, 2, 3],
          flexWrap: 'wrap',
          width: ['100%', 'auto', 'auto'],
        }}
      >
        <Link
          to="/"
          sx={{
            variant: 'styles.navlink',
            width: 'inherit',
            mr: 1,
          }}
        >
          <Avatar />
          <Text
            sx={{
              ml: [1, 2, 3],
              flexGrow: 1,
              borderBottomStyle: 'dashed',
              borderBottomWidth: '16px',
              borderImageSource: `url("${border}")`,
              borderBottomColor: 'primary',
              borderImageSlice: '90 0',
              borderImageRepeat: 'round',
            }}
          >
            Revealestine
          </Text>
        </Link>
      </Flex>

      <Flex
        sx={{
          columnGap: [1, 2, 3],
        }}
      >
        <Link
          to="/nan"
          sx={{
            variant: 'styles.navitem',
            mr: 1,
          }}
        >
          NaN
        </Link>
        <Link
          to="/timeline"
          sx={{
            variant: 'styles.navitem',
            mr: 1,
          }}
        >
          Timeline
        </Link>

        <Link
          to="/propaganda"
          sx={{
            variant: 'styles.navitem',
            mr: 1,
          }}
        >
          Propaganda
        </Link>
        <ColorButton mode={mode} onClick={handleColorMode} />
      </Flex>
    </header>
  )
}
