import { Theme, ThemeUIContextValue, useThemeUI } from 'theme-ui'

const makeTheme = <T extends Theme>(t: T) => t

const theme = makeTheme({
  initialColorModeName: 'initial',
  fontSizes: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.125rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
  ],
  easingFunctions: {
    0: 'linear',
    1: 'cubic-bezier(0.828, 0.39, 0.236, 1.167)',
    2: 'ease',
  },
  letterSpacings: { tracked: '0.1em', negative: '-0.05em', large: '0.25em' },
  fonts: {
    heading:
      "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif",
    body: "'Averia Serif Libre', sans-serif, -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif",
    mono: "'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace",
  },
  lineHeights: { solid: 1, heading: 1.25, body: 1.5 },
  borderRadius: [0, '4px', '6px', '8px', '16px', '32px', '9999px'],
  durations: ['250ms', '375ms', '500ms'],
  space: [0, '4px', '8px', '16px', '32px', '64px', '128px', '256px', '512px'],
  borderStyles: ['none', 'solid'],
  colors: {
    background: '#fff2e7',
    text: '#130707',
    border: '#f8babb',
    primary: '#f59d9f',
    accent: '#f28183',
    muted: '#f8c0ae',
    'green-low': [
      '#cbf0dd',
      '#c4e9d6',
      '#bce1ce',
      '#b3d7c5',
      '#a8ccba',
      '#9bbfad',
      '#8caf9e',
      '#7a9d8c',
      '#658877',
      '#4d6f5f',
    ],
    'green-high': [
      '#11e523',
      '#13d820',
      '#14ca1d',
      '#16bd1a',
      '#17af17',
      '#19a215',
      '#1a9412',
      '#1c870f',
      '#1d790c',
      '#1f6c09',
    ],
    'red-low': [
      '#ff5c5c',
      '#f55a5a',
      '#eb5959',
      '#e15757',
      '#d75556',
      '#cd5454',
      '#c35253',
      '#b95051',
      '#af4f50',
      '#a54d4e',
    ],
    'red-high': [
      '#e01212',
      '#d31111',
      '#c60f11',
      '#b90e10',
      '#ac0c0f',
      '#9e0b0f',
      '#91090e',
      '#84080d',
      '#77060d',
      '#6a050c',
    ],
    grey: [
      '#ffffff',
      '#e3e3e3',
      '#c6c6c6',
      '#aaaaaa',
      '#8e8e8e',
      '#717171',
      '#555555',
      '#393939',
      '#1c1c1c',
      '#000000',
    ],
    modes: {
      dark: {
        background: '#0a311e',
        text: '#effff9',
        border: '#0a311e',
        primary: '#c5f9dd',
        accent: '#45dc8f',
        muted: '#134d33',
      },
      light: {
        background: '#fff2e7',
        text: '#130707',
        border: '#f8babb',
        primary: '#f59d9f',
        accent: '#f28183',
        muted: '#ffdab8',
      },
      red: {
        background: '#632917',
        text: '#fbdad0',
        border: '#fbdad0',
        primary: '#f59d9f',
        accent: '#f28183',
        muted: '#8b3a21',
      },
      green: {
        background: '#f0f8f5',
        text: '#25594a',
        border: '#f0f8f5',
        primary: '#0fb780',
        accent: '#45dc8f',
        muted: '#d0e8df',
      },
    },
  },
  borderWidths: [0, '1px', '2px', '4px', '8px'],
  name: 'System',
  gradients: {
    0: 'repeating-linear-gradient(80deg, #E4312b 26%, #c72830 28%)',
    1: 'linear-gradient(248deg, #302023 32%, #79282e 41%)',
    2: 'radial-gradient(circle at 28% 93%, #000000 36%, #cbf0dd 95%)',
    3: 'linear-gradient(92deg, #302023 43%, #a8232a 70%)',
    4: 'linear-gradient(330deg, #ff2834 40%, #74c099 57%)',
    5: 'conic-gradient(from 35deg at 73% 15%, #E4312b 17%, #000000 43%)',
    6: 'linear-gradient(136deg, #ff2834 2%, #a8232a 73%)',
    7: 'linear-gradient(338deg, #000000 29%, #ff2834 42%)',
    8: 'conic-gradient(from 34deg at 93% 1%, #000000 52%, #129350 70%)',
    9: 'linear-gradient(116deg, #000000 19%, #E4312b 46%)',
  },
  boxShadows: {
    0: '0px 1px 1px 0px hsla(0, 0%, 0%, 0.035), 0px 2px 2px 0px rgba(0, 0, 0, 0.125), 0px 4px 4px 0px rgba(0, 0, 0, 0.125), 0px 8px 8px 0px rgba(0, 0, 0, 0.125), 0px 16px 16px 0px rgba(0, 0, 0, 0.125)',
    1: '0px 1px 1px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 8px 0px rgba(0, 0, 0, 0.15)',
    2: '0px 1px 1px 0px rgba(0, 0, 0, 0.11), 0px 2px 2px 0px rgba(0, 0, 0, 0.11), 0px 4px 4px 0px rgba(0, 0, 0, 0.11), 0px 8px 8px 0px rgba(0, 0, 0, 0.11), 0px 16px 16px 0px rgba(0, 0, 0, 0.11), 0px 32px 32px 0px rgba(0, 0, 0, 0.11)',
    3: '0px 1px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 2px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 8px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.05)',
    4: '0px 1px 1px 0px rgba(0, 0, 0, 0.25), 0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 8px 0px rgba(0, 0, 0, 0.1), 0px 16px 16px 0px rgba(0, 0, 0, 0.05)',
    5: '0px 19px 38px 0px rgba(0, 0, 0, 0.08), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.16), 0px 8px 8px 0px rgba(0, 0, 0, 0.2)',
    6: '0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12), 0 0 0 1px rgba(255,255,255,.175)',
    7: '0 2px 2px rgba(0,0,0,0.16), 0 4px 4px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.16), 0 12px 12px rgba(0,0,0,0.16), 0 24px 24px rgba(0,0,0,0.16), 0 0 0 1px rgba(255,255,255,.2)',
    border: 'inset 0px 0px 0px 1px currentColor',
    'border-light-overlay': 'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.13)',
    'border-dark-overlay': 'inset 0px 1px 0px 1px rgba(0, 0, 0, 0.13)',
  },
  sizes: {
    container: 768,
    wide: 1280,
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'text',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
    },
  },
  buttons: {
    primary: {
      borderRadius: 4,
      color: 'text',
      bg: 'background',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        cursor: 'pointer',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
  },
  text: {
    title: {
      fontSize: [5, 6, 6],
      fontWeight: 'bold',
    },
    'headline-xl': {
      fontSize: [5, 7, 8],
      fontWeight: 'bold',
      my: 1,
    },
    'headline-l': {
      fontSize: [5, 6, 7],
      fontWeight: 'bold',
      my: 4,
    },
    question: {
      fontSize: [2, 4, 5],
      fontWeight: 'bold',
      my: 1,
    },
    heading: {
      fontSize: [1, 1, 3],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      my: 1,
    },
    medium: {
      fontSize: [2, 2, 3],
    },
    small: {
      fontSize: 1,
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    italic: {
      fontStyle: 'italic',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontSize: 2,
      bg: 'background',
      transitionProperty: 'background-color',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '.4s',
    },
    h1: {
      fontSize: [1, 2, 5],
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      my: 4,
    },
    h2: {
      mt: 4,
      mb: 2,
    },
    h3: {
      fontSize: 2,
    },
    h4: {
      variant: 'text.heading',
    },
    h5: {
      variant: 'text.heading',
    },
    h6: {
      variant: 'text.heading',
    },
    p: {
      lineHeight: 1.5,
    },
    a: {
      color: 'text',
      ':hover': {
        cursor: 'pointer',
        color: 'accent',
      },
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
    },
    hr: {
      border: 0,
      my: 4,
      borderBottom: '1px solid',
      borderColor: 'muted',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      py: 2,
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      py: 2,
      borderBottom: '1px solid',
      borderColor: 'muted',
    },
    blockquote: {
      fontWeight: 'bold',
      mx: 0,
      px: 3,
      my: 5,
      borderLeft: '4px solid',
    },
    div: {
      '&.footnotes': {
        variant: 'text.small',
      },
    },
    navlink: {
      variant: 'text.caps',
      color: 'inherit',
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      ':hover,:focus': {
        color: 'primary',
      },
    },
    navitem: {
      variant: 'styles.navlink',
      borderBottomStyle: 'solid',
      borderBottomColor: 'text',
      borderBottomWidth: '3px',
      fontWeight: 'bold',
      fontSize: ['10px', '16px', '16px'],
    },
    shortcut: {
      variant: 'styles.navitem',
      fontSize: '14px',
      borderBottom: 'solid 2px',
    },
  },
})

export type ExactTheme = typeof theme

interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
  theme: ExactTheme
}

export const useTheme = useThemeUI as unknown as () => ExactContextValue

export default theme
