import { keyframes } from '@emotion/react'
import watermelon from '../images/watermelon.png'

import { Avatar as BaseAvatar } from 'theme-ui'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const Avatar = () => (
  <BaseAvatar
    src={watermelon}
    sx={{
      animation: `${spin} 4s linear infinite`,
      backgroundColor: 'muted',
      width: [24, 32, 32],
    }}
  />
)

export default Avatar
