import {
  PropsWithChildren,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

interface HeaderContextValue {
  headerHeight: number
  headerRef: RefObject<HTMLElement> | null
}

const HeaderContext = createContext<HeaderContextValue>({
  headerHeight: 0,
  headerRef: null,
})

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const headerRef = useRef<HTMLElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [])

  return (
    <HeaderContext.Provider value={{ headerHeight, headerRef }}>
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeader = () => {
  const context = useContext(HeaderContext)
  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider')
  }
  return context
}
